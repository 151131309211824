/* PDF Modal Styles */
/* **************** */
/*
.custom-modal .modal-dialog {
	min-width: 665px;  -- Adjust to fit the content 
	max-width: unset;  -- Remove Bootstrap's default max-width 
}
*/


.custom-dialog {
	min-width: unset;
}
.custom-dialog .modal-body {
	background-image: url('../public/images/PDF-background.png');
	background-repeat: no-repeat;
	background-position: center; /* Centers the image within the container */
}
.body-container {
	/* background-image: url('/public/images/PDF-background.png'); */
	padding: 20px 30px 0 30px;
	width: 100%; /* Makes the container take up full width of its parent */
	height: auto;
}
.body-container > p {
	margin: 0 20px;
	padding: 0 30px;
}

@media (min-width: 576px) {
	.custom-dialog {
		min-width: 576px;
	}
	.body-container {
		padding: 100px 75px 100px 75px;
		min-width: 576px;
	}
}

@media (min-width: 768px) {
	.custom-dialog {
		min-width: 665px;
	}
	.body-container {
		padding: 110px 125px 110px 125px;
		min-width: 665px;
	}
}

@media (min-width: 992px) {
	.custom-dialog {
		min-width: 665px;
	}
	.body-container {
		padding: 110px 125px 110px 125px;
	}
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 5px; /* Optional: Adjust the space between grid items */
	margin: 20px 0;
}

.grid-item {
	padding: 0;
	text-align: center;

	border-radius: 10px;
	border: solid 2px transparent;	

	display: flex;
	justify-content: center;
	align-items: center;
}
.grid-item:hover {
	border-color: #000000;
	cursor: pointer;
}