.form-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media (min-width: 576px) {
	.form-body {
		flex-direction: row;
	}
}

.suggestions-list {
	list-style-type: none;
	border: solid 1px #CACACA;
	position: absolute;
	z-index: 20;
	background-color: #FFFFFF;
	box-shadow: 0 5px 5px #CCC;
	padding: 0;
}

.suggestions-list li {
	margin: 0;
	padding: 10px 20px;
}
.suggestions-list li:hover {
	background-color: #CACACA;
	cursor: pointer;
}