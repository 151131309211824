/* @font-face { font-family: ArialRoundedMtBold; src: url('./arial-rounded-mt-bold.ttf'); }  */
/* @font-face { font-family: SiaChristmasFontNew; src: url('/public/fonts/SiaChristmasFont-New.ttf'); } */
@font-face { font-family: SiaChristmasFontNew; src: url('../public/fonts/SiaChristmasFont-New.ttf'); }

body {
  margin: 0;
  
	font-family: SiaChristmasFontNew;
 /* 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
*/
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* .container {
	font-family: ArialRoundedMtBold;
	min-height: calc(100vh - 200px);
} */
