#root {
	margin: 0; padding: 0;
	background-color: #FFFFFF;
	color: #000000;
	text-align: center;
}
  
a.red {
	color: red;
	font-weight: bold;
	text-decoration: underline;
}

h2 {
	text-align: center;
}