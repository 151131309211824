.mapPage {
	/* background-image: url('/public/images/SnowBackground.png'); */
	background-image: url('../../public/images/SnowBackground.png');
	background-size: cover;
	min-height: 100vh;
}


.backLink {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 0 20px 0;
}

@media (min-width: 576px) {
	.backLink {
		justify-content: end;	
	}
}


.mapContainer {
	max-width: 1400px;
	width: 100%;
	margin: 0 auto;
}


.wreath-container {
	position: relative;
	display: inline-block;
	width: 100%;
	max-width: 400px; /* Adjust max-width as needed */
}

.wreath-container div {
	display: block;
	width: 100%;
	height: auto;
	border-radius: 50%; /* Optional: makes the image circular */
}

.wreath-svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none; /* Allows interaction with the image itself */
}

/* ********************************************************************* */
.custom-marker-clustering-control-panel .attribution {
	font-size: 0.75em;
  }
  
  @keyframes appear {
	from {
	  opacity: 0.5;
	  scale: 0.8;
	}
	80% {
	  scale: 1.05;
	}
	to {
	  opacity: 1;
	  scale: 1;
	}
  }
  
  .custom-marker-clustering-map {
	/* width: 100vw;
	height: 100vh; */
	height: 600px;
  }
  
  .custom-marker-clustering-map .marker {
	box-sizing: border-box;
	/* border-radius: 50%; */
	padding: 8px;
	/* border: 1px solid white; */
	color: white;
  
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
  
	animation: appear 150ms both;
  }
  
  .custom-marker-clustering-map .marker svg {
	width: 100%;
	vector-effect: non-scaling-stroke;
  }
  
  .custom-marker-clustering-map .marker.feature {
	width: 35px;
	height: 35px;
  
	/* background-color: #3f5b72; */
	filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.5));
  }
  
  .custom-marker-clustering-map .marker.cluster {
	width: 56px;
	height: 56px;
	/* background-color: #618bad; */
	filter: drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.7));
	/* overflow: hidden; */
	padding-bottom: 0;
  }
  
.custom-marker-clustering-map .marker.cluster span {
	box-sizing: border-box;
	border-radius: 50%;
	
	position: absolute;
	top: -2px; right: -2px;

	background-color: white;
	color: rgba(0, 0, 0, 1);
	padding: 3px 6px;
	
	/* oversized by the padding of the container */
	/* width: calc(100% + 16px); */
	text-align: center;
	/*
	margin-top: 6px;
	height: 30px;
	*/
}